import React, { useEffect, useRef, useState } from "react";
import {
  Widget,
  renderCustomComponent,
  setQuickButtons,
  addUserMessage,
  addResponseMessage,
} from "react-chat-widget";
import { marked } from "marked";
import * as DOMPurify from "dompurify";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";

import "react-chat-widget/lib/styles.css";

const LLM_COOKIE_NAME = "llm_user_id";

/* OBSERVERS */
/**
 * Watch for when the footer intersects with the viewport, and move floating agent accordingly.
 */
const footerObserver = new IntersectionObserver(
  function (entries) {
    const widgetContainer = document.querySelector(".rcw-widget-container");
    if (!widgetContainer) {
      return;
    }
    if (entries[0].isIntersecting) {
      widgetContainer.style.bottom = "122px";
    } else if (!entries[0].isIntersecting) {
      widgetContainer.style.bottom = "32px";
    }
  },
  { threshold: [0] }
);

/* HELPER FUNCTIONS */
function isMobile() {
  return (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  );
}

function addCookieToHeader(header) {
  return header;
}

function removeLoadingMessage() {
  document.querySelectorAll(".rcw-loading").forEach((el) => {
    el.parentElement.remove();
  });
}

function giveNoAnswer(isAzureGcpRelated) {
  if (isAzureGcpRelated) {
    renderCustomComponent(GcpAzureRelatedResponse, {}, true);
  } else {
    renderCustomComponent(NoAnswerFound, {}, true);
  }
}

function receivedFeedbackHandler(
  isThumbsUp,
  { question, answer, timestamp },
  hasBeenClicked
) {
  if (hasBeenClicked) {
    return;
  }
  addUserMessage(isThumbsUp ? "👍" : "👎");
  try {
    fetch("https://chatapi.databricks.com/feedback", {
      method: "POST",
      headers: addCookieToHeader({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        question,
        answer,
        answer_timestamp: timestamp,
        reaction: isThumbsUp ? "thumb_up" : "thumb_down",
      }),
    });
  } catch {}

  addResponseMessage("Thank you for your feedback.");
}

const CANNED_RESPONSES = {
  AZURE_GCP_ANSWER:
    "As an AI assistant, I'm currently trained on documentation and knowledge base about Databricks on AWS. Please refer to the documentation for Databricks on Azure and Databricks on GCP in the meantime.",
  NO_ANSWER:
    "Sorry, I can't find an answer to the question you asked. But you may be able to find the answer in Databricks Documentation or Databricks Knowledge Base",
};

/* CUSTOM COMPONENTS */
function LoadingMessage() {
  return <div className="rcw-response rcw-message-text rcw-loading"></div>;
}

function Introduction() {
  return (
    <div className="rcw-response">
      <div className="rcw-message-text">
        Hi, I'm the Databricks AI assistant. You can ask me any questions you
        have about using Databricks. <br />
        <br /> Examples: <br /> 1. How do I load files into Databricks? <br />{" "}
        2. How do you deploy my model for serving? <br /> 3. How can I convert
        my files to parquet files? <br />
        <br />
        <div className="rcw-disclaimer">
          Disclaimer: I may display inaccurate information that doesn’t
          represent Databricks views.
        </div>
      </div>
    </div>
  );
}

function NoAnswerFound() {
  return (
    <div className="rcw-response">
      <div className="rcw-message-text">
        Sorry, I can't find an answer to the question you asked. But you may be
        able to find the answer in{" "}
        <Link
          url="https://docs.databricks.com"
          name="Databricks Documentation"
        />{" "}
        or{" "}
        <Link
          url="https://kb.databricks.com"
          name="Databricks Knowledge Base"
        />
        .
      </div>
    </div>
  );
}

function GcpAzureRelatedResponse() {
  return (
    <div className="rcw-response">
      <div className="rcw-message-text">
        As an AI assistant, I'm currently trained on documentation and knowledge
        base about Databricks on AWS. Please refer to the documentation for{" "}
        <Link
          url="https://learn.microsoft.com/en-us/azure/databricks"
          name="Databricks on Azure"
        />{" "}
        and{" "}
        <Link url="https://docs.gcp.databricks.com" name="Databricks on GCP" />{" "}
        in the meantime.
      </div>
    </div>
  );
}

function NetworkErrorResponse() {
  return (
    <div className="rcw-response">
      <div className="rcw-message-text rcw-rate-limited">
        There was a network error. Please try again later.
      </div>
    </div>
  );
}

function QuestionSeparator() {
  return <hr className="rcw-question-separator" />;
}

function Launcher(handleToggle) {
  const [showChat, setShowChat] = useState(false);

  useEffect(() => {
    // send API
    if (showChat) {
      fetch("https://chatapi.databricks.com/metrics", {
        credentials: "include",
        method: "POST",
        headers: addCookieToHeader({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          event: "opened_assistant",
        }),
      }).catch(() => {});
    }
  }, [showChat]);

  const toggleChat = () => {
    setShowChat(!showChat);
    handleToggle();
  };

  return (
    <button className="rcw-custom-launcher" onClick={toggleChat}>
      {showChat ? (
        <img
          className="rcw-close-launcher"
          src="https://agao00.github.io/static/media/chevron-down.041d46cd.svg"
          alt="close"
        />
      ) : (
        <>
          <img
            className="rcw-open-launcher"
            src="https://agao00.github.io/static/media/white-speech-bubble.svg"
            alt="open"
          />
          <img
            className="rcw-open-text"
            src="https://agao00.github.io/static/media/tooltip.svg"
            alt="Try our AI assistant"
          />
        </>
      )}
    </button>
  );
}

function Link({ url, name }) {
  return (
    <a
      href={url}
      className="rcw-link"
      target="_blank"
      rel="noopener noreferrer"
    >
      {name ? name : url}
    </a>
  );
}

function ResponseWithLinks({ answer, sources }) {
  return (
    <div className="rcw-response">
      <div
        className="rcw-message-text"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(marked.parse(answer)),
        }}
      ></div>
      {sources.length > 0 ? (
        <div className="rcw-sources">
          <div className="rcw-sources-title">{`Source${
            sources.length === 1 ? "" : "s"
          }`}</div>
          {sources.map((src) => (
            <Link url={src}></Link>
          ))}
        </div>
      ) : null}
    </div>
  );
}

function Feedback({ question, answer, timestamp }) {
  const [clicked, setClicked] = useState(false);

  return (
    <>
      <div
        className="rcw-response rcw-feedback rcw-thumbs-up"
        onClick={() => {
          receivedFeedbackHandler(
            true,
            { question, answer, timestamp },
            clicked
          );
          setClicked(true);
        }}
      >
        <span role="img" aria-label="thumbs up">
          👍
        </span>
      </div>
      <div
        className="rcw-response rcw-feedback rcw-thumbs-down"
        onClick={() => {
          receivedFeedbackHandler(
            false,
            { question, answer, timestamp },
            clicked
          );
          setClicked(true);
        }}
      >
        <span role="img" aria-label="thumbs down">
          👎
        </span>
      </div>
    </>
  );
}

/* MAIN COMPONENT */
export function LLMAssistantWidget() {
  const [isEnabled, setIsEnabled] = useState(false);
  const history = useRef([]);

  async function fetchAnswer(question) {
    const historyEntry = {
      question: {
        question,
        timestamp: new Date().getTime(),
      },
      answer: {
        answer: "",
        timestamp: 0,
      },
    };

    let response;

    try {
      response = await fetch("https://chatapi.databricks.com/doc_qa", {
        method: "POST",
        headers: addCookieToHeader({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          question,
          history: history.current,
        }),
      });
    } catch {}

    if (response?.ok) {
      const responseBody = await response.text();
      const parsedResponseBody = JSON.parse(responseBody);
      historyEntry.answer.timestamp = parsedResponseBody.timestamp;

      const isRelatedToAzureGCP = parsedResponseBody.related_to_azure_gcp;
      if (isRelatedToAzureGCP || !parsedResponseBody.has_answer) {
        giveNoAnswer(isRelatedToAzureGCP);
        historyEntry.answer.answer = isRelatedToAzureGCP
          ? CANNED_RESPONSES["AZURE_GCP_ANSWER"]
          : CANNED_RESPONSES["NO_ANSWER"];
      } else {
        renderCustomComponent(ResponseWithLinks, parsedResponseBody, true);
        historyEntry.answer.answer = parsedResponseBody.answer;
      }

      renderCustomComponent(
        Feedback,
        {
          question,
          answer: parsedResponseBody.answer,
          timestamp: parsedResponseBody.timestamp,
        },
        true
      );

      history.current.push(historyEntry);
      if (history.current.length > 3) {
        history.current = history.current.slice(-3);
      }
    } else {
      // show network error response for all 4xx and 5xx errors
      renderCustomComponent(NetworkErrorResponse, {}, true);
    }

    removeLoadingMessage();
    document.querySelector(".rcw-sender").style.display = "none";
    setQuickButtons([
      {
        label: "Ask another question",
        value: "",
      },
    ]);
  }

  async function handleNewUserMessage(newMessage) {
    renderCustomComponent(LoadingMessage, {}, true);
    document.querySelector(".rcw-sender").style.visibility = "hidden";
    await fetchAnswer(newMessage);
  }

  function handleQuickButtonClicked() {
    setQuickButtons([]);
    document.querySelector(".rcw-sender").style.visibility = "visible";
    document.querySelector(".rcw-sender").style.display = "flex";
    document.querySelector("input.rcw-new-message").focus();
    renderCustomComponent(QuestionSeparator);
  }

  useEffect(() => {
    async function getIsEnabled() {
      try {
        const response = await fetch("https://chatapi.databricks.com/flags", {
          method: "POST",
          headers: addCookieToHeader({
            "Content-Type": "application/json",
            "X-Webpage-Url": window.location.href,
          }),
          body: JSON.stringify({}),
        });
        const responseBody = await response.json();
        setIsEnabled(responseBody.assistant_enabled);
      } catch {
        setIsEnabled(false);
      }
    }

    getIsEnabled();

    // Set performance cookie llm_user_id if not already set
    // If user did not consent to performance cookies, OneTrust will block this from being set
    if (!Cookies.get(LLM_COOKIE_NAME)) {
      const date = new Date();
      Cookies.set(LLM_COOKIE_NAME, uuidv4(), {
        domain: ".databricks.com",
        expires: date.setDate(date.getDate() + 183), // 6 months
      });
    }
  }, []);

  useEffect(() => {
    if (isEnabled) {
      renderCustomComponent(Introduction, {}, true);
      // Only watch for footer when footer exists
      const footer = document.querySelector("footer#footer");
      if (footer) {
        footerObserver.observe(footer);
      }
    }
  }, [isEnabled]);

  return isMobile() || !isEnabled ? null : (
    <div className="LLMAssistantWidget">
      <Widget
        handleNewUserMessage={handleNewUserMessage}
        handleQuickButtonClicked={handleQuickButtonClicked}
        title="Databricks AI assistant"
        subtitle=""
        profileAvatar="https://agao00.github.io/static/media/dblogo.56ac0149.jpg"
        titleAvatar="https://agao00.github.io/static/media/dblogo.56ac0149.jpg"
        launcher={(handleToggle) => Launcher(handleToggle)}
      />
    </div>
  );
}
